<template>
  <div class="h-screen">
    <transition name="fade">
      <Loader v-if="$store.state.globalLoader.show" :header="$store.state.globalLoader.head" :key="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader"></Loader>
    </transition>
    <main class="min-h-full bg-cover bg-bottom sm:bg-bottom background">      
      <div class="max-w-7xl mx-auto px-4 py-48 text-center  sm:px-6 sm:py-48 lg:px-8 lg:py-48" v-if="this.$route.query.type === 'SubscriptionExpired'">
        <h1 class="mt-2 text-3xl font-bold text-white tracking-tight sm:text-3xl">Your subscription has expired.</h1>
        <p class="mt-2 text-xs font-medium text-black text-opacity-70">Your system administrator {{creatorEmail}} has to pay for the subscription.</p>
        <div class="mt-6">
          <a href="/dashboard" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:hover:bg-opacity-50"> Go back home </a>
         </div>
      </div>
      <div class="test max-w-7xl mx-auto px-4 py-48 text-center  sm:px-6 sm:py-48 lg:px-8 lg:py-48" v-if="this.$route.query.type === 'SubscriptionExpiredAdmin'">
        <h1 class="mt-2 text-3xl font-bold text-white tracking-tight sm:text-3xl">Your subscription has expired.</h1>
        <p class="mt-2 text-xs font-medium text-black text-opacity-70">Click the button to continue with your plan.</p>
        <div class="mt-6">
          <a :href="stripeLink" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:hover:bg-opacity-50"> Manage your plan </a>
         </div>
      </div>
      <div class="test max-w-7xl mx-auto px-4 py-48 text-center  sm:px-6 sm:py-48 lg:px-8 lg:py-48" v-if="this.$route.query.type === 'noSubscription'">
        <h1 class="mt-2 text-3xl font-bold text-white tracking-tight sm:text-3xl">You did not complete the payment.</h1>
        <p class="mt-2 text-xs font-medium text-black text-opacity-70">Click the button to continue.</p>
        <div class="mt-6">
          <a :href="stripeLink" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:hover:bg-opacity-50"> Go to stripe </a>
        </div>
      </div>
      <div class="max-w-7xl mx-auto px-4 py-48 text-center  sm:px-6 sm:py-48 lg:px-8 lg:py-48" v-if="this.$route.query.type === 'SiteNotFound'">
        <!-- <h1 class="mt-2 text-3xl font-extrabold text-white tracking-tight sm:text-4xl">404</h1> -->
        <h1 class="mt-2 text-3xl font-bold text-white tracking-tight sm:text-3xl">Error 404</h1>
        <p class="mt-2 text-xs font-medium text-black text-opacity-50">Page not found.</p>
        <div class="mt-6">
          <a href="/dashboard" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:hover:bg-opacity-50"> Go back home </a>
         </div>
      </div>
    </main>
  </div>
</template>

<script>
import Loader from '../components/Loader.vue';
export default {
  components: {
    Loader
  },
   watch:{
    '$route'()
    {
      this.$store.commit("resetGlobalLoader");
    }
  },
  mounted() {
    if (this.$route.query.stripeLink !== undefined) {
      this.stripeLink = window.atob(this.$route.query.stripeLink)
    }
      setTimeout(() => {
        this.$store.commit("resetGlobalLoader");
      }, 500)
  },
  data() {
    return {
      creatorEmail: this.$route.query.email,
      stripeLink: undefined
    }
  },
  created() {
     this.$store.commit('setGlobalLoader',{
          show: true,
          head: "Changing site..",
          subheader: `Do not close this tab.`,
          success: false
      });
  }
}
</script>

<style>
.background {
background-image: url('../assets/errorbg.png')
}

.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-leave-to {
  opacity: 0;
}
</style>